import { Vue, Component, Prop } from 'vue-property-decorator';
import { formatCreatedAt } from '@/lib/utils/Utils';
import { TableRow } from '@/lib/layouts/page/table.interface';
import UserModule from '@/admin/store/user';

@Component
class TableMixin extends Vue {
    @Prop({
        required: true,
        default: () => [
            {
                id: 'id',
                name: 'id',
                sort: 'desc',
                visible: true,
            },
            {
                id: 'name',
                name: 'ФИО',
                visible: true,
            },
            {
                id: 'email',
                name: 'E-mail',
                visible: true,
            },
        ],
    })
    readonly titles!: any[];
    @Prop({ required: true, default: () => [] }) readonly hidden_columns!: any[];
    @Prop({ required: true, default: () => ['id'] }) readonly sort_fields!: any[];
    @Prop({
        required: true,
        default: () => [
            {
                id: 1,
                name: 'John Snow',
                email: 'john@wall.com',
            },
            {
                id: 2,
                name: 'Jamie Lannister',
                email: 'jamie@lannister.com',
            },
        ],
    })
    readonly rows!: any[] | {};
    @Prop() readonly rowsById!: any;
    @Prop({
        default: () => {
            return {
                edit: 'edit',
                delete: 'delete',
                sort: 'sort',
            };
        },
    })
    readonly actionsHandler!: any;
    @Prop() readonly routeName!: string;
    @Prop() readonly routeParam!: string;
    @Prop() readonly actionPagination!: string;
    @Prop() readonly pagination!: {} | null;
    @Prop() readonly itemsPagination!: { action: () => void; items: string } | null;

    @Prop() readonly isStatusColumn!: boolean;
    @Prop() readonly isEdit!: boolean;
    @Prop() readonly isTotal!: boolean;
    @Prop() readonly colspan!: any;
    @Prop() readonly isBottomTable!: boolean;
    @Prop({
        default: () => {
            return [];
        },
    })
    readonly alignRightColumn!: any[];
    @Prop() readonly amountItens!: string;
    @Prop({ default: false }) readonly isActionsHidden!: boolean;

    touch: any = null;
    isExportOpen = false;
    isShowDetails = false;
    detailsId: any = null;
    createdAtNames = ['created_at', 'createdAt', 'actualTo'];
    routeNames = ['id', 'name', 'title', 'fullName', 'topic'];

    getIsDisplayRouteLink(row: TableRow, cellId: string): boolean {
        const routeCheck = Boolean(this.routeNames.includes(cellId) && this.routeName);
        if ('youRegistered' in row && UserModule.isOperator) {
            return routeCheck && row.youRegistered;
        }

        return routeCheck;
    }

    actionHandler(name: string, id: string | number, data: any) {
        this.$emit('click', { action: name, id, data });
    }
    toggleSort(params: any) {
        this.$emit('click', params);
    }
    scrollHandler(e: Event) {
        (this.$refs.scrollbar_bottom as HTMLElement).scrollLeft = (e.target as HTMLElement)?.scrollLeft;
    }
    selectAmount(value: any) {
        this.$emit('select-amount', value);
    }
    emitAdd() {
        this.$emit('add');
    }
    parseDate(date: string) {
        if (!date) {
            return '–';
        }

        return formatCreatedAt(date);
    }
    mounted() {
        // this.initScroll();
    }
    updated() {
        // this.initScroll();
    }
}

export default TableMixin;


import { Component, Vue, Prop } from 'vue-property-decorator';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import IconFont from '@/components/icons/IconFont.vue';

library.add(faChevronRight, faChevronLeft);

interface PaginationInterface {
    currentPage: number;
    totalPages: number;
    totalItems: number;
}

@Component({
    components: {
        FontAwesomeIcon,
        IconFont,
    },
})
export default class Pagination extends Vue {
    @Prop({ required: true }) readonly data!: PaginationInterface;
    @Prop({ required: true }) readonly action!: (num: string | number) => void;
    @Prop({ type: Object, default: null }) readonly items!: {
        action: (value: string | number) => void;
        items: string;
    } | null;

    startPage = 1;
    stateClass = '';
    classOpen = 'open';
    listAmountItems = ['25', '50', '100', '250'];
    visibleList = false;

    setPage(number: number) {
        this.action(number);
    }

    next() {
        const page =
            this.data.currentPage + 1 <= this.data.totalPages ? this.data.currentPage + 1 : this.data.currentPage;
        this.action(page);
    }

    before() {
        const page = this.data.currentPage - 1 > 0 ? this.data.currentPage - 1 : this.data.currentPage;
        this.action(page);
    }

    toggleSelect() {
        this.stateClass = this.stateClass === this.classOpen ? '' : this.classOpen;
        this.visibleList = !this.visibleList;
    }

    selectAmount(value: string): void {
        this.items?.action?.(value);
        this.$emit('select-amount', value);
    }

    documentClick(e: Event) {
        const el: any = this.$refs.dropdownAmountSelect;
        const target: any = e.target;

        if (this.visibleList) {
            if (el && el !== target && !el.contains(target)) {
                this.visibleList = false;
                this.stateClass = '';
            }
        }
    }

    created() {
        document.body.addEventListener('click', this.documentClick);
    }

    updated() {
        document.body.addEventListener('click', this.documentClick);
    }

    destroyed() {
        document.body.addEventListener('click', this.documentClick);
    }
}

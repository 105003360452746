var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('thead',{staticClass:"mr-table-titles__wrapper"},[_c('tr',[(_vm.isStatusColumn)?_c('th',{staticClass:"small"},[_c('div',{staticClass:"mr-table-title-item__wrapper"})]):_vm._e(),_vm._l((_vm.titles),function(title,index){return _vm._t("default",function(){return [(title && !_vm.hiddenColumns.includes(title.id))?_c('th',{key:`table-title_${index}`,staticClass:"mr-table-title-item",class:{
                    hidden: _vm.hiddenColumns.includes(title.id),
                    small: title.id === 'id' || title.id === 'isActive',
                    'align-right': _vm.alignRightColumn.includes(title.id),
                }},[_c('div',{staticClass:"mr-table-title-item__wrapper cp-flex align-center",class:{ small: title.id === 'id' || title.id === 'isActive' }},[_c('div',{class:{ sort: _vm.sortFields.includes(title.id) }},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(title.id === '_actions' && title.name === 'add'),expression:"title.id === '_actions' && title.name === 'add'"}],staticClass:"cp-flex",on:{"click":_vm.addRow}},[_c('add-icon',{staticClass:"cp-cursor-pointer",attrs:{"color":"#9B9BC7"}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(title.id === '_actions' && title.name === 'check'),expression:"title.id === '_actions' && title.name === 'check'"}],staticClass:"cp-flex"},[_c('div',{staticClass:"mr-fake-checkbox__wrapper"},[_c('label',{staticClass:"mr-cursor-pointer"},[_c('input',{attrs:{"type":"checkbox"},domProps:{"checked":title.value},on:{"change":function($event){return _vm.action('check-all')}}}),_c('span',{staticClass:"check-mark"})])])]),(_vm.sortFields.includes(title.id) && _vm.alignRightColumn.includes(title.id))?_c('div',{staticClass:"mr-table-sort__wrapper cp-table-sort__single",on:{"click":function($event){return _vm.toggleSort(title.id, title.sort)}}},[_c('span',{staticClass:"mr-table-sort__item-burger icon-burger",class:{
                                    'sort-asc': title.sort === 'ASC',
                                    'sort-desc': title.sort === 'DESC',
                                }})]):_vm._e(),_c('span',{directives:[{name:"show",rawName:"v-show",value:(title.id !== '_actions'),expression:"title.id !== '_actions'"}],staticClass:"mr-table-title-name"},[_vm._v(_vm._s(title.name))]),(_vm.sortFields.includes(title.id) && !_vm.alignRightColumn.includes(title.id))?_c('div',{staticClass:"mr-table-sort__wrapper cp-table-sort__single",on:{"click":function($event){return _vm.toggleSort(title.id, title.sort)}}},[_c('span',{staticClass:"mr-table-sort__item-burger icon-burger",class:{
                                    'sort-asc': title.sort === 'ASC',
                                    'sort-desc': title.sort === 'DESC',
                                }})]):_vm._e()])])]):_vm._e()]})})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }